require('./bootstrap');
require('./components/inputAutoSubmit');
require('./bwpublisher');

$(function () {
    // Mobile Nav
    $('.toggle-nav').on('click', function () {
        $('button.toggle-nav, header nav, .nav-overlay').toggleClass('active');
    });

    // Desktop Nav
    document.addEventListener('click', (event) => {
        let $tar = document.querySelector('body header nav'),
            withinBoundaries = event.composedPath().includes($tar);

        if (! withinBoundaries) {
            $('header nav .sub-menu').removeClass('active');
        }
    });

    $('header nav button').on('click', function () {
        let $visible = $(this).siblings('.sub-menu')[0].checkVisibility({
            visibilityProperty: true
        });

        $('header nav .sub-menu').removeClass('active');

        if (! $visible) {
            $(this).siblings('.sub-menu').addClass('active');
        }
    });

    // Back to Top
    $('footer .top').on('click', function () {
        $('html, body').animate({
            scrollTop: 0
        }, '300');
    });

    // Tables
    $('table').each(function () {
        $(this).wrap('<div class="responsive-table"></div>');
    });

    // Industries Slider
    $('.industries-slider').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 1500,
        arrows: false,
        dots: true,
        appendDots: $('.industries-slider-container .slider-nav'),
    });

    // Salt Grades
    $('.salt-grades-selector').slick({
        slidesToShow: 1,
        infinite: false,
        arrows: false,
        dots: false,
        centerMode: true,
        centerPadding: 'clamp(5rem, -5rem + 44.444vw, 20rem)',
        mobileFirst: true,
        focusOnSelect: true,
        initialSlide: 4,
        responsive: [{
            breakpoint: 799,
            settings: {
                slidesToShow: 3,
                centerPadding: 'clamp(5rem, -33.25rem + 68vw, 17.75rem)',
            },
        }, {
            breakpoint: 1199,
            settings: {
                slidesToShow: 5,
                centerPadding: 'clamp(5rem, -70rem + 100vw, 20rem)',
            }
        }, {
            breakpoint: 1439,
            settings: {
                slidesToShow: 9,
                centerMode: false,
            }
        }],
    });

    $('.salt-grades-selector .salt-grade').on('click', function (e) {
      $('.salt-grades-selector .salt-grade').removeClass('active');
      $(this).addClass('active');
    });

    $(window).on('resize', function (e) {
        setDefaultSalt(5);
    });
    setDefaultSalt(5);

    function setDefaultSalt($tar) {
        if ($(window).width() >= 1440) {
            $('.salt-grades-selector .salt-grade:nth-child(' + $tar + ')').trigger('click');
        }
    }

    // Industries Slider
    $('.slider-industries').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        fade: true,
        cssEase: 'linear',
        autoplay: false,
        autoplaySpeed: 4000,
    });

    $('.slider-industries-nav').slick({
        slidesToShow: 6,
        slidesToScroll: 1,
        infinite: false,
        asNavFor: '.slider-industries',
        focusOnSelect: true,
        arrows: false,
    });

    // Salt Grades Slider
    $('.slider-salt-grades').slick({
        slidesToShow: 8,
        arrows: false,
        dots: false,
        cssEase: 'linear',
        autoplay: false,
        infinite: false,
        autoplaySpeed: 4000,
        responsive: [{
            breakpoint: 900,
            settings: {
                slidesToShow: 1,
            },
        }],
    });

    function saltSelector() {
        var newWindowWidth = $(window).width();

        if (newWindowWidth < 900) {
            $('#salt-mobile-active').empty().append(
                $('.slider-salt-grades .slick-slide')
                    .eq(Math.floor($('.slider-salt-grades .slick-slide').length / 2))
                    .children()
                    .clone()
            );

            $('.slider-salt-grades .slick-slide').on('click', function () {
                $('#salt-mobile-active').empty();
                $('#salt-mobile-active').append($(this).children().clone());
            });
        } else {
            $('.slider-salt-grades .slick-slide')
                .eq(Math.floor($('.slider-salt-grades .slick-slide').length / 2))
                .addClass('centered');

            $('.slider-salt-grades .slick-slide').on('click', function () {
                $('.slider-salt-grades .slick-slide').removeClass('centered');
                $(this).addClass('centered');
            });
        }
    }

    $(function () {
        $(window).on('resize', function (e) {
            saltSelector();
        });

        saltSelector();
    });

    // CTA Slider
    $('.cta-slider').slick({
        slidesToShow: 8,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        pauseOnFocus: false,
        pauseOnHover: false,
        fade: false,
        infinite: true,
        cssEase: 'linear',
        autoplay: true,
        autoplaySpeed: 4000,
        responsive: [{
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
            },
        }, {
            breakpoint: 400,
            settings: {
                slidesToShow: 2,
            },
        }],
    });

    // Product Show page
    $('.product-images').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        fade: true,
        cssEase: 'ease',
        autoplay: false,
    });
});

// Mobile category pop up
$(function () {
    $('.popup-trigger').on('click', function (e) {
        e.preventDefault();

        $('header').css('display', 'none');
        $('.popup').addClass('is-visible');
    });

    $('.popup').on('click', function (e) {
        if ($(e.target).is('.popup-close') || $(e.target).is('.popup')) {
            e.preventDefault();

            $('header').css('display', 'block');
            $(this).removeClass('is-visible');
        }
    });

    $(document).on('keyup', function (e) {
        if (e.key === 'Escape') {
            $('header').css('display', 'block');
            $('.popup').removeClass('is-visible');
        }
    });
});
